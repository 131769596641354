//react components
import React from "react";
//css library
import styled from "@emotion/styled";
//mui components
import { useTheme } from '@mui/styles';
import { spacing } from "@mui/system";
import MuiTextField from "@mui/material/TextField";
const TextFieldSpacing = styled(MuiTextField)(spacing);
const TextField = styled(TextFieldSpacing)`
`;
//sqlitecloud components
import InputFilledWithCustomLabel from "@generic-components/Form/InputFilledWithCustomLabel";

const ModalInputFilled = (props) => {
	//get theme
	const theme = useTheme();
	// credential input style
	const credentialInputStyle = {
		background: "transparent",
		fontFamily: "Roboto Mono",
		color: theme.palette.secondary.accent2,
		fontSize: '0.6875rem !important',
		lineHeight: '0.983125rem',
		letterSpacing: '0.010625rem',
		fontWeight: '500',
	}
	return (
		<InputFilledWithCustomLabel {...props}>
			<TextField
				type={props.type}
				fullWidth
				sx={{ pl: 2 }}
				inputProps={{
					style: {
						paddingTop: '0',
					},
				}}
				InputProps={{
					sx: credentialInputStyle,
					endAdornment: props.endAdornment
				}}
				FormHelperTextProps={{
					sx: {
						margin: "1px 0 0 0",
						fontSize: "0.5rem"
					}
				}}
				variant="standard"
				error={props.error}
				helperText={props.helperText}
				value={props.value}
				onChange={props.onChange}
				disabled={props.disabled}
			/>
		</InputFilledWithCustomLabel>
	);
};

export default ModalInputFilled;
//react
import React, { useEffect, useState } from "react";
//css library
import styled from "@emotion/styled";
//mui components
import { spacing } from "@mui/system";
import Grid from '@mui/material/Grid';
import MenuItem from "@mui/material/MenuItem";
import MuiTextField from "@mui/material/TextField";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
const TextFieldSpacing = styled(MuiTextField)(spacing);
const TextField = styled(TextFieldSpacing)`
`;
//sqlitecloud lib
import { validateString, validateStringnonEncodingCharsInUrl } from "@lib/utils";
//sqlitecloud hooks
import { useEditData } from "@custom-hooks/useApi";
//sqlitecloud components
import ModalContent from "@generic-components/ModalContent";
//components specific utils
import { environmentType } from "./utils";


const EditProject = ({ project, setDisableRowDuringAction, callback }) => {
	//get method to call editing api
	const editDataOpt = {
		errorCallback: () => { setDisableRowDuringAction(false); callback() },
		mutatedCallback: () => { setDisableRowDuringAction(false); callback() }
	};
	const { loading, editedData, mutatingData, error, editData } = useEditData(editDataOpt);
	//handle edit project parameters
	const [projectName, setProjectName] = useState(project.name);
	const [projectNameValidation, setProjectNameValidation] = useState({ valid: true, messages: [""] });
	const [passwordValidation, setPasswordValidation] = useState({ valid: true, messages: [""] });
	const [userName, setUserName] = useState(project.admin_username);
	const [description, setDescription] = useState(project.description);
	const [password, setPassword] = useState(project.admin_password);
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	const [canCreate, setCanCreate] = useState(false);
	const handleChangeUserName = (event) => {
		setUserName(event.target.value);
	}
	const handleChangePassword = (event) => {
		const newPassword = event.target.value;
		const validation = validateStringnonEncodingCharsInUrl(newPassword)
		setPassword(newPassword);
		setPasswordValidation(validation);
	}
	const handleChangeDescription = (event) => {
		setDescription(event.target.value);
	}
	const [environment, setEnvironment] = useState(project.env ? project.env : 0);
	const handleChangeEnvironment = (event) => {
		setEnvironment(event.target.value);
	}
	useEffect(() => {
		let validateProjectName = { valid: false };
		if (projectName !== "") {
			validateProjectName = validateString(projectName);
			setProjectNameValidation(validateProjectName);
		}
		if (
			projectName !== "" &&
			validateProjectName.valid &&
			passwordValidation.valid &&
			userName !== "" &&
			environment !== "" &&
			(
				projectName !== project.name ||
				description !== project.description ||
				password !== project.admin_password ||
				userName !== project.admin_username ||
				environment !== project.env
			)
		) {
			setCanCreate(true);
		} else {
			setCanCreate(false);
		}
	}, [projectName, userName, passwordValidation, description, environment, password, userName]);
	//method call to edit project
	const edit = async (e) => {
		e.preventDefault();
		setDisableRowDuringAction(true);
		let body = {
			"name": projectName,
			"description": description,
			"admin_username": userName,
			"env": environment
		};
		if (password !== "") {
			body.admin_password = password;
		}
		const opt = {
			method: "POST",
			endpoint: `/api/projects/${project.id}/edit`,
			endpointCallLocation: "EditProject.js",
			body: body,
			mutateApis: [
				["/api/projects", "useGetProjects"]
			]
		}
		await editData(opt);
	};
	//render
	return (
		<ModalContent
			actionType="edit"
			title={"Edit Project"}
			showLoader={loading || mutatingData}
			editButtonLabel={"Save"}
			disabled={!canCreate || loading || mutatingData || error}
			runCallback={edit}
		>
			{
				!loading && !error && !mutatingData &&
				<Grid item>
					<form noValidate autoComplete="off">
						<Grid container flexDirection={"column"} rowGap={"1.75rem"}>
							<TextField
								autoFocus
								label="Project name *"
								type="text"
								fullWidth
								error={!projectNameValidation.valid}
								helperText={projectNameValidation.messages[0]}
								value={projectName}
								onChange={(event) => {
									setProjectName(event.target.value);
								}}
							/>
							<TextField
								label="Description"
								InputProps={{
									sx: { height: "auto" }
								}}
								fullWidth
								multiline
								rows={4}
								value={description}
								onChange={handleChangeDescription}
							/>
							<TextField
								label="Environment  *"
								fullWidth
								select={true}
								value={environment}
								onChange={handleChangeEnvironment}
							>
								{
									environmentType.map((environment, i) => {
										return (
											<MenuItem key={environment.value} value={environment.value}>{environment.label}</MenuItem>
										)
									})
								}
							</TextField>
							<TextField
								id="user-name"
								label="Administrator Username *"
								type="text"
								fullWidth
								value={userName}
								onChange={handleChangeUserName}
							/>
							<TextField
								fullWidth
								id="password"
								label="Administrator Password"
								type={showPassword ? 'text' : 'password'}
								value={password}
								onChange={handleChangePassword}
								error={!passwordValidation.valid}
								helperText={passwordValidation.messages.join(" - ")}
								InputProps={
									{
										endAdornment:
											<InputAdornment position="end" >
												<IconButton
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
									}
								}
							/>
						</Grid>
					</form>
				</Grid>
			}
		</ModalContent>
	);
}

export default EditProject;

//react
import React, { Fragment, useState } from "react";
//css library
import styled from "@emotion/styled";
//mui components
import { spacing } from "@mui/system";
import Grid from "@mui/material/Grid"
import MuiLinearProgress from "@mui/material/LinearProgress"
const LinearProgress = styled(MuiLinearProgress)(spacing);

function PageLoader({ show, mt = 0 }) {
	return (
		<>
			{
				show &&
				<Grid mt={mt} item>
					<LinearProgress />
				</Grid>
			}
		</>
	);
}

export default PageLoader;

//react components
import React from "react";
//mui components
import { useTheme } from '@mui/styles';
import Typography from "@mui/material/Typography";
//sqlitecloud components
import InputWithCustomLabel from "@generic-components/Form/InputWithCustomLabel";



const ModalIndicator = (props) => {
	//get theme
	const theme = useTheme();
	// fixed setting style
	const fixedSettingStyle = {
		fontFamily: "Roboto Mono",
		color: theme.palette.secondary.accent3,
		textAlign: "right",
	}
	return (
		<InputWithCustomLabel {...props}>
			<Typography as="div" variant="14px-reg" sx={fixedSettingStyle}>
				{props.info}
			</Typography>
		</InputWithCustomLabel>
	);
};

export default ModalIndicator;
//react components
import React from "react";
//css library
import styled from "@emotion/styled";
//mui components
import { spacing } from "@mui/system";
import MuiTextField from "@mui/material/TextField";
const TextFieldSpacing = styled(MuiTextField)(spacing);
const TextField = styled(TextFieldSpacing)`
`;
//sqlitecloud components
import InputWithCustomLabel from "@generic-components/Form/InputWithCustomLabel";

const ModalInput = (props) => {
	return (
		<InputWithCustomLabel {...props}>
			<TextField
				autoFocus={props.autoFocus}
				type={props.type}
				fullWidth
				className="subvariant-modal-form"
				error={props.error}
				helperText={props.helperText}
				value={props.value}
				onChange={props.onChange}
				disabled={props.disabled}
				InputProps={props.InputProps}
			/>
			{props.children}
		</InputWithCustomLabel>
	);
};

export default ModalInput;
//react
import React, { useContext } from "react";
//sqlitecloud context
import { StateContext } from "@context/StateContext"
//sqlitecloud hooks
import { useEditData } from "@custom-hooks/useApi";
//sqlitecloud components
import ModalContent from "@generic-components/ModalContent";

function DeleteProject({ project, setDisableRowDuringAction, callback }) {
	//get setting method from state context for actual project id
	const { setActualProjectIndex } = useContext(StateContext)
	//get method to call editing api
	const editDataOpt = {
		errorCallback: () => { setDisableRowDuringAction(false); callback() },
		mutatedCallback: () => { setDisableRowDuringAction(false); callback() },
		editedCallback: () => { setActualProjectIndex(-1) },
	};
	const { loading, mutatingData, editedData, error, editData } = useEditData(editDataOpt);
	//method call to remove node
	const remove = async (e) => {
		e.preventDefault();
		setDisableRowDuringAction(true);
		const opt = {
			method: "GET",
			endpoint: `/api/projects/${project.id}/delete`,
			endpointCallLocation: "DeleteProject.js",
			mutateApis: [
				["/api/projects", "useGetProjects"]
			]
		}
		await editData(opt);
	}
	//render
	return (
		<ModalContent
			actionType="strong-confirm"
			title={`Delete project?`}
			showLoader={loading || mutatingData}
			disabled={loading || mutatingData || error}
			noCallback={callback}
			yesCallback={remove}
			resourceType="project"
			confirmString={project.name}
			verifyString="delete my project"
		/>
	)
}


export default DeleteProject;

//react
import React, { useContext, useEffect } from "react";
//formbricks
import formbricks from "@formbricks/js";
//mui components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
//sqlitecloud context
import { StateContext } from "@context/StateContext";
//sqlitecloud components
import CreateProject from "./CreateProject";

const FirstProject = ({ }) => {
	//get actual user from state context	
	const { formbricksInit } = useContext(StateContext);
	useEffect(() => {
		const callSurveyForm = async () => {
			try {
				await formbricks.track("first-project");
			} catch (error) {
				console.log(error);
			}
		}
		if (formbricksInit) {
			callSurveyForm();
		}
	}, [formbricksInit])
	//render
	return (
		<Grid width={"100%"} height={"100%"} container flexDirection={"column"} flexWrap={"nowrap"} justifyContent={"space-between"} alignItems={"center"} overflowY={"auto"} overflowX={"hidden"}>
			<Grid item>
				<Container
					sx={{
						boxSizing: "content-box",
						overflowY: "visile",
						overflowX: "hidden",
						pt: 8
					}}
					maxWidth="xs"
				>
					<Box px={10}>
						<CreateProject firstProject={true} />
					</Box>
				</Container >
			</Grid>
			<Grid item>
				<svg style={{marginBottom:"-0.3rem"}} width="1440" height="250" viewBox="0 0 1440 250" fill="none" xmlns="http://www.w3.org/2000/svg">
					<mask id="mask0_280_1741" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="-117" y="0" width="1557" height="532">
						<path d="M1154 7.80364C1084.7 7.80364 1061.21 60.1507 1018.5 60.1507C975.789 60.1507 874.101 182.796 741.5 182.796C608.899 182.796 520.764 89.0869 464.5 89.0869C408.236 89.0869 358.778 0 290.5 0C156.8 0 185.564 113.46 -117 113.46L0 532H1440V76.7486C1358.75 77.474 1338.56 75.2397 1282 60.1507C1225.44 45.0618 1269.8 7.80364 1200.5 7.80364H1154Z" fill="#4A5154" />
					</mask>
					<g mask="url(#mask0_280_1741)">
						<line x1="2.5" y1="-44.5439" x2="2.49997" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="26.5" y1="-44.5439" x2="26.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="50.5" y1="-44.5439" x2="50.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="74.5" y1="-44.5439" x2="74.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="98.5" y1="-44.5439" x2="98.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="122.5" y1="-44.5439" x2="122.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="146.5" y1="-44.5439" x2="146.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="170.5" y1="-44.5439" x2="170.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="194.5" y1="-44.5439" x2="194.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="218.5" y1="-44.5439" x2="218.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="242.5" y1="-44.5439" x2="242.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="266.5" y1="-44.5439" x2="266.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="290.5" y1="-44.5439" x2="290.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="314.5" y1="-44.5439" x2="314.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="338.5" y1="-44.5439" x2="338.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="362.5" y1="-44.5439" x2="362.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="386.5" y1="-44.5439" x2="386.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="410.5" y1="-44.5439" x2="410.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="434.5" y1="-44.5439" x2="434.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="458.5" y1="-44.5439" x2="458.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="482.5" y1="-44.5439" x2="482.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="506.5" y1="-44.5439" x2="506.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="530.5" y1="-44.5439" x2="530.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="554.5" y1="-44.5439" x2="554.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="578.5" y1="-44.5439" x2="578.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="602.5" y1="-44.5439" x2="602.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="626.5" y1="-44.5439" x2="626.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="650.5" y1="-44.5439" x2="650.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="674.5" y1="-44.5439" x2="674.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="698.5" y1="-44.5439" x2="698.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="722.5" y1="-44.5439" x2="722.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="746.5" y1="-44.5439" x2="746.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="770.5" y1="-44.5439" x2="770.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="794.5" y1="-44.5439" x2="794.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="818.5" y1="-44.5439" x2="818.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="842.5" y1="-44.5439" x2="842.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="866.5" y1="-44.5439" x2="866.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="890.5" y1="-44.5439" x2="890.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="914.5" y1="-44.5439" x2="914.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="938.5" y1="-44.5439" x2="938.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="962.5" y1="-44.5439" x2="962.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="986.5" y1="-44.5439" x2="986.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="1010.5" y1="-44.5439" x2="1010.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="1034.5" y1="-44.5439" x2="1034.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="1058.5" y1="-44.5439" x2="1058.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="1082.5" y1="-44.5439" x2="1082.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="1106.5" y1="-44.5439" x2="1106.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="1130.5" y1="-44.5439" x2="1130.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="1154.5" y1="-44.5439" x2="1154.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="1178.5" y1="-44.5439" x2="1178.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="1202.5" y1="-44.5439" x2="1202.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="1226.5" y1="-44.5439" x2="1226.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="1250.5" y1="-44.5439" x2="1250.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="1274.5" y1="-44.5439" x2="1274.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="1298.5" y1="-44.5439" x2="1298.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="1322.5" y1="-44.5439" x2="1322.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="1346.5" y1="-44.5439" x2="1346.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="1370.5" y1="-44.5439" x2="1370.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="1394.5" y1="-44.5439" x2="1394.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
						<line x1="1418.5" y1="-44.5439" x2="1418.5" y2="532.061" stroke="#578FDA" strokeWidth="3" />
					</g>
					<path fillRule="evenodd" clipRule="evenodd" d="M537.102 187.356C584.338 209.582 628.326 230.28 673 230.28C706.479 230.28 752.999 215.477 809.93 197.361C933.262 158.116 1105.45 103.324 1299.78 149.796C1341.68 159.838 1390.18 167.105 1440 172.531V512.28H1422.5C1450.81 527.458 1470 556.776 1470 590.433C1470 639.658 1428.95 679.603 1378.36 679.603C1375.91 679.603 1373.58 679.424 1371.19 679.241H1371.19L1371.01 679.227C1373.2 685.059 1374.43 691.267 1374.43 697.788C1374.43 727.637 1349.55 751.905 1318.81 751.905C1304.82 751.905 1292.06 746.826 1282.27 738.548C1254.23 777.678 1207.7 803.263 1154.98 803.263C1075.78 803.263 1010.43 745.446 1001.02 670.824C989.098 676.405 975.757 679.603 961.643 679.603C923.802 679.603 891.297 657.254 877.317 625.389C853.015 642.413 823.202 652.442 790.996 652.442C787.037 652.442 783.276 652.153 779.423 651.857L779.422 651.857L779.131 651.834C782.669 661.258 784.647 671.289 784.647 681.828C784.647 730.06 744.471 769.274 694.824 769.274C672.239 769.274 651.631 761.067 635.81 747.691C590.535 810.92 515.388 852.263 430.249 852.263C302.333 852.263 196.794 758.837 181.598 638.256C162.343 647.274 140.798 652.442 118.004 652.442C36.2999 652.442 -30 587.896 -30 508.352C-30 475.698 -18.8264 445.571 -0.000461578 421.399V198.853C92.4206 203.878 136.448 188.297 187.052 170.389C220.925 158.401 257.746 145.37 314 136.779C401.275 123.445 472.249 156.841 537.102 187.356Z" fill="#C3D7F2" />
				</svg>
			</Grid>
		</Grid>
	);
}

export default FirstProject;
//react
import React, { useState, useRef } from "react";
//next.js
import NextLink from "next/link";
//mui components
import { useTheme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem"
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
//sqlitecloud components
import Card from "@generic-components/Card";
import OpenDialog from "@generic-components/OpenDialog";
import RowTableActionsMenu from "@generic-components/RowTableActionsMenu";
import SingleProjectInfo from "./SingleProjectInfo"
import EditProject from "./EditProject"
import DeleteProject from "./DeleteProject"
//components specific utils
import { convertEnvCodeToEnvString } from "./utils";

//item used to trigger config proejct dialog
const ConfigProjectItem = ({ callback, disabled }) => {
	return (
		<MenuItem
			disabled={disabled}
			onClick={(event) => {
				event.preventDefault();
				event.stopPropagation();
				callback();
			}}
		>
			<ListItemIcon>
				<InfoIcon fontSize="small" />
			</ListItemIcon>
			<ListItemText>Config</ListItemText>
		</MenuItem>
	)
}
const ConfigProjectDialog = ({ callback, disabled, project, actionOnOpen, actionOnClose }) => {
	return (
		<OpenDialog
			size="small-modal"
			style="custom"
			actionComponent={<ConfigProjectItem disabled={disabled} />}
			actionOnClose={[callback, actionOnClose]}
			actionOnOpen={actionOnOpen}
		>
			<SingleProjectInfo projectId={project.id} />
		</OpenDialog>
	)
}
//item used to trigger edit proejct dialog
const EditProjectItem = ({ callback, disabled }) => {
	return (
		<MenuItem
			disabled={disabled}
			onClick={(event) => {
				event.preventDefault();
				event.stopPropagation();
				callback();
			}}
		>
			<ListItemIcon>
				<EditIcon fontSize="small" />
			</ListItemIcon>
			<ListItemText>Edit</ListItemText>
		</MenuItem>
	)
}
const EditDatabaseDialog = ({ callback, disabled, project, setDisableRowDuringAction, actionOnOpen, actionOnClose }) => {
	return (
		<OpenDialog
			size="small-modal"
			style="custom"
			actionComponent={<EditProjectItem disabled={disabled} />}
			actionOnClose={[callback, actionOnClose]}
			actionOnOpen={actionOnOpen}
		>
			<EditProject project={project} setDisableRowDuringAction={setDisableRowDuringAction} />
		</OpenDialog>
	)
}
//item used to trigger delete project dialog
const DeleteProjectItem = ({ callback, disabled }) => {
	return (
		<MenuItem
			disabled={disabled}
			onClick={(event) => {
				event.preventDefault();
				event.stopPropagation();
				callback();
			}}
		>
			<ListItemIcon>
				<DeleteIcon fontSize="small" />
			</ListItemIcon>
			<ListItemText>Delete Project</ListItemText>
		</MenuItem>
	)
}
const DeleteProjectDialog = ({ callback, disabled, project, setDisableRowDuringAction, actionOnOpen, actionOnClose }) => {
	return (
		<OpenDialog
			size="small-modal"
			style="custom"
			actionComponent={<DeleteProjectItem disabled={disabled} />}
			actionOnClose={[callback, actionOnClose]}
			actionOnOpen={actionOnOpen}
			decorationVariant={2}
		>
			<DeleteProject project={project} setDisableRowDuringAction={setDisableRowDuringAction} />
		</OpenDialog>
	)
}

const SingleProjectCard = ({ project }) => {
	//get theme
	const theme = useTheme();
	//get project id
	const projectId = project.id;
	const name = project.name;
	const description = project.description;
	const nodes_count = project.nodes_count;
	const address = project.project_address;
	const env = convertEnvCodeToEnvString(project.env, theme);
	//define reference to row
	const cardRef = useRef(null);
	//handle disabling row during actions
	const [disableRowDuringAction, setDisableRowDuringAction] = useState(false);
	//handle highlight row
	const [highlightRow, setHighlightRow] = useState(false);
	const highlight = () => {
		setHighlightRow(true);
	}
	const deHighlight = () => {
		setHighlightRow(false);
		cardRef.current?.resetHover();
	}
	//define action menu items
	const menuItems = [
		{
			type: "component",
			component: <ConfigProjectDialog actionOnOpen={highlight} actionOnClose={deHighlight} setDisableRowDuringAction={setDisableRowDuringAction} project={project} />
		},
		{
			type: "component",
			component: <EditDatabaseDialog actionOnOpen={highlight} actionOnClose={deHighlight} setDisableRowDuringAction={setDisableRowDuringAction} project={project} />
		},
		{
			type: "divider",
		},
		{
			type: "component",
			component: <DeleteProjectDialog actionOnOpen={highlight} actionOnClose={deHighlight} setDisableRowDuringAction={setDisableRowDuringAction} project={project} />
		},
	]
	//render
	return (
		<NextLink href={`/projects/${projectId}/nodes/`} passHref>
			<a className="unstyled-link">
				<Card
					ref={cardRef}
					type={highlightRow ? "info-1" : "info-4"}
					hoverType={"info-1"}
					customSx={{ padding: "1.25rem 1.5rem", height: "11rem", cursor: "pointer" }}
				>
					<Grid width={"100%"} container flexDirection={"column"} height={"100%"} justifyContent={"space-between"} wrap="nowrap">
						<Grid item width={"100%"}>
							<Grid width={"100%"} container flexDirection={"row"} justifyContent={"space-between"} columnGap={"1rem"} rowGap={"0.75rem"} wrap="nowrap">
								<Grid item flexShrink={1} >
									<Grid container flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} columnGap={"0.75rem"} rowGap={"0.75rem"} wrap="nowrap">
										<Grid item>
											<Grid
												sx={{ pointerEvents: "none" }}
												container
												flexDirection={"row"}
												alignItems={"center"}
												justifyContent={"center"}
											>
												<img
													style={{
														width: "2rem",
														background: "transparent",
													}}
													alt={`SQLite Cloud project ${projectId}`}
													src="/static/img/logo/logo-light@4x.png"
												/>
											</Grid>
										</Grid>
										<Grid item>
											<Typography as="div" variant="14px-med" gutterBottom={true}
												sx={{
													"whiteSpace": "nowrap",
													"overflow": "hidden",
													"textOverflow": "ellipsis",
													"maxWidth": "13rem",
													'&:hover': {
														textDecoration: 'underline',
													},
												}}>
												{name}
											</Typography>
											<Typography as="div" variant="14px-med" color={theme.palette.neutral.darkGreyBis} sx={{ wordBreak: "break-all" }}>
												{address}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item as="div" flexGrow={1} >
									<Grid container justifyContent={"flex-end"}>
										<Typography as="div" variant="12px-med" width={"6.625rem"} sx={{ background: env.color }} px={2} py={0} borderRadius={2} textAlign={"center"}>
											{env.label}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<Grid container flexDirection={"column"} rowGap={"0.75rem"} wrap="nowrap">
								<Grid item>
									<Typography as="div" variant="14px-med" sx={{ lineHeight: "1.2", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
										{description}
									</Typography>
								</Grid>
								<Grid item>
									<Grid container flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} columnGap={"1rem"} wrap="nowrap">
										<Grid item>
											<Typography as="div" variant="14px-med" color={theme.palette.neutral.darkGreyBis}>
												{/* Last activity ... - {nodes_count} node{(nodes_count === 0 || nodes_count === 1) ? "" : "s"} cluster */}
												{nodes_count} node{(nodes_count === 0 || nodes_count === 1) ? "" : "s"} cluster
											</Typography>
										</Grid>
										<Grid item>
											<RowTableActionsMenu iconStyle="points" menuItems={menuItems} actionOnOpen={cardRef.current?.forceHover} actionOnClose={cardRef.current?.resetHover} />
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Card>
			</a>
		</NextLink>
	);
};

export default SingleProjectCard;